// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import html2pdf from "html2pdf.js";
import { useToast } from "vue-toastification";
const getHtml = () => {
  const $stageList = document.querySelector("#stageList") as HTMLElement; //
  const $map = document.querySelector("#map") as HTMLElement;
  const $wrapper = document.createElement("div");
  $stageList.style.display = "block";
  $map.style.display = "block";
  $wrapper.appendChild($map);
  $wrapper.appendChild($stageList);
  return $wrapper;
};

const getPdfOptions = (estateTitle: string) => {
  const elementsToIgnoreById = ["stage-nav-wrapper", "legend", "btn-mobile"];
  return {
    filename: `Masterplan ${estateTitle}.pdf`,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: {
      scale: 2,
      ignoreElements: ({ id }: HTMLElement) =>
        elementsToIgnoreById.some((className) => id.includes(className)),
    },
    jsPDF: {
      unit: "in",
      format: "letter",
      orientation: "portrait",
      margin: { top: 0, right: 0, bottom: 0, left: 0 },
    },
  };
};

export async function generateMasterplanPDF(
  estateTitle: string
): Promise<void> {
  try {
    const htmlContent = getHtml();
    const pdfOptions = getPdfOptions(estateTitle);
    await html2pdf().set(pdfOptions).from(htmlContent).save();
  } catch (error) {
    const toast = useToast();
    toast.error("Something went wrong, please try again later");
  }
}
