import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e2474bbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "password-progress" }
const _hoisted_2 = { class: "progress" }
const _hoisted_3 = { class: "rules" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (item, index) => {
        return _createElementVNode("span", {
          class: _normalizeClass(["progress-item", { active: $setup.isActiveProgressItem(index, $setup.props.password) }]),
          key: item
        }, null, 2)
      }), 64))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.ruleTitles, (item, index) => {
        return _createElementVNode("div", {
          class: _normalizeClass(["rule", { 'rule-correct': $setup.isCorrectRule(index, $setup.props.password) }]),
          key: item
        }, _toDisplayString(item), 3)
      }), 64))
    ])
  ]))
}